import styled from 'styled-components'
import { tokens, tokensRaw, Typography } from '@vp/swan'

interface FeaturedNamesProps {
  names: string[]
}

const FeaturedNamesContainer = styled.div`
  position: absolute;
  height: 100%;
  width: 50%;
  top: 0;
  right: 0;

  @media (min-width: ${tokensRaw.SwanBaseBreakpointXlStart}) {
    right: -5%;
  }

  @media (max-width: ${tokensRaw.SwanBaseBreakpointXsEnd}) {
    width: 100%;
  }
`

interface TextBoxPositionProps {
  top?: string
  left?: string
  rotate?: string
}

const TextBoxPosition = styled.div<TextBoxPositionProps>`
  position: absolute;
  top: ${({ top = '50%' }) => top};
  left: ${({ left = '50%' }) => left};
  ${({ rotate = '0deg' }) => `
    transform: translate(-50%, -50%) rotate(${rotate});
  `}
`

const PrimaryText = styled(Typography)`
  font-size: ${tokens.SwanSemFontSizeX2large} !important;

  @media (max-width: ${tokensRaw.SwanBaseBreakpointXsEnd}) {
    font-size: ${tokens.SwanSemFontSizeStandard} !important;
  }
`

const SecondaryText = styled(Typography)`
  font-size: ${tokens.SwanSemFontSizeXlarge} !important;

  @media (max-width: ${tokensRaw.SwanBaseBreakpointXsEnd}) {
    font-size: ${tokens.SwanSemFontSizeSmall} !important;
  }
`

const SparklesContainer = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(70%, -65%);
  width: 90px;
  height: 90px;

  @media (max-width: ${tokensRaw.SwanBaseBreakpointXsEnd}) {
    width: 40px;
    height: 40px;
  }
`

const WhiteTextBox = styled.div`
  padding: 15px 20px;
  color: ${tokens.SwanBaseColorBlack}; // override darkMode banner setting text to white

  @media (max-width: ${tokensRaw.SwanBaseBreakpointXsEnd}) {
    padding: 8px 10px;
  }

  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 10px;
    background-color: ${tokens.SwanBaseColorWhite};
  }

  &::after {
    content: '';
    position: absolute;
    z-index: -2;
    top: 5px;
    left: 5px;
    right: -5px;
    bottom: -3px;
    border-radius: 10px;
    background-color: #b63318;
    transform: rotate(1deg);
  }
`

const GreyTextBox = styled.div`
  padding: 10px 20px;
  border: ${tokens.SwanBaseBorderWidth200} solid ${tokens.SwanBaseColorWhite};
  border-radius: 10px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.4);
  background-color: rgba(58, 58, 58, 0.8);

  @media (max-width: ${tokensRaw.SwanBaseBreakpointXsEnd}) {
    padding: 5px 10px;
  }
`

export const FeaturedNames = ({ names }: FeaturedNamesProps) => {
  return (
    <FeaturedNamesContainer>
      <TextBoxPosition left='36%' top='52%' rotate='2deg'>
        <WhiteTextBox>
          <PrimaryText noWrap fontSkin='editorial-content'>
            {names[0]}
          </PrimaryText>
        </WhiteTextBox>
        <SparklesContainer>
          <img src='https://cms.cloudinary.vpsvc.com/image/upload/c_scale,dpr_auto,f_auto,w_auto/vista-logos-pages/en-US/business-name-generator/sparkles.svg' alt='' />
        </SparklesContainer>
      </TextBoxPosition>
      <TextBoxPosition left='37%' top='72%' rotate='-2deg'>
        <GreyTextBox>
          <SecondaryText noWrap fontSkin='editorial-content'>
            {names[1]}
          </SecondaryText>
        </GreyTextBox>
      </TextBoxPosition>
      <TextBoxPosition left='48%' top='88%' rotate='2deg'>
        <GreyTextBox>
          <SecondaryText noWrap fontSkin='editorial-content'>
            {names[2]}
          </SecondaryText>
        </GreyTextBox>
      </TextBoxPosition>
    </FeaturedNamesContainer>
  )
}
