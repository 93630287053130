import { useState } from 'react'
import styled from 'styled-components'
import {
  Banner,
  BannerDescription,
  BannerFootnote,
  BannerImage,
  BannerImageContainer,
  BannerLinks,
  BannerText,
  BannerTextContainer,
  BannerTitle,
  Button,
  FluidImage,
  FormField,
  FormInputGroup,
  Icon,
  StandardForm,
  TextInput,
  tokens,
  tokensRaw
} from '@vp/swan'
import { useTrack } from '../../../../utils/analytics'
import FormErrorWrapper from '../../../../components/FormErrorWrapper/FormErrorWrapper'
import { maxKeywordInputLength } from '../BusinessNameResults/BusinessNameResults'
import { FeaturedNames } from './FeaturedNames'

const StyledTextInput = styled(TextInput)`
  &::placeholder {
    /* For darkMode banner */
    color: ${tokens.SwanBaseColorGrey600};
  }
`

const HeroImage = styled.img`
  width: 100%;
  object-position: center center;

  @media (min-width: ${tokensRaw.SwanBaseBreakpointMdStart}) {
    object-position: right center;
  }
}
`

interface HeroProp {
  onSubmit: () => void
  inputKeyword: string
  onSearchInputChange: (value: string) => void
}

const Hero = ({ onSubmit, inputKeyword, onSearchInputChange }: HeroProp) => {
  const [formErrorMessage, setFormErrorMessage] = useState('')

  const track = useTrack()

  function trackQuerySubmitted () {
    track('Query Submitted On Business Name Generator', {
      pageName: 'Business Name Generator:Hero',
      pageSection: 'Hero',
      category: 'Hero',
      label: 'Content:Hero',
      eventDetail: '/business-name-generator/business-name-generatorHeroshowNameIdeas',
      navigationDetail: 'showNameIdeas',
      query: inputKeyword
    })
  }

  const handleOnSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!inputKeyword.trim().length) {
      setFormErrorMessage('Please describe your business to generate name ideas.')
    } else if (!formErrorMessage) {
      trackQuerySubmitted()
      onSubmit()
    }
  }

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value

    if (value.length > maxKeywordInputLength) {
      setFormErrorMessage(`Maximum ${maxKeywordInputLength} character limit`)
    } else {
      setFormErrorMessage('')
      onSearchInputChange(value)
    }
  }

  return (
    <Banner variant='full-width-image' darkMode>
      <BannerTextContainer>
        <BannerText>
          <BannerTitle as='h1'>Business name generator</BannerTitle>
          <BannerDescription marginBottom='to-actions' as='p'>
            Enter your business description to generate name ideas instantly, and all
            for free!
          </BannerDescription>
          <StandardForm onSubmit={handleOnSubmit}>
            <FormField>
              <FormInputGroup margin={0}>
                <StyledTextInput
                  value={inputKeyword}
                  onChange={handleOnChange}
                  placeholder='Cozy cafe serving coffee and homemade pastries'
                  aria-label='Enter keywords to describe your business'
                />
                <FormErrorWrapper errorMessage={formErrorMessage} />
                <BannerLinks marginTop={2}>
                  <Button skin='primary' type='submit'>
                    Show name ideas <Icon iconType='magic' size='standard' />
                  </Button>
                </BannerLinks>
              </FormInputGroup>
            </FormField>
          </StandardForm>
          <BannerFootnote style={{ color: tokensRaw.SwanBaseColorGrey400 }}>
            You are responsible for any keywords or text you input. The business names
            are generated by a third-party generative AI service and are suggestions
            only. The names may be used by other businesses or subject to third-party
            rights. VistaPrint does not accept any liability or responsibility arising
            in any way from your use of the business names suggested.
          </BannerFootnote>
        </BannerText>
      </BannerTextContainer>
      <BannerImageContainer>
        <BannerImage>
          <FluidImage as='picture'>
            <source
              srcSet='https://cms.cloudinary.vpsvc.com/image/upload/c_scale,dpr_auto,f_auto,w_auto/vista-logos-pages/en-US/business-name-generator/BusinessNameGenerator_1840x1000_sm_xs_rev_b.webp'
              media={`(max-width: ${tokensRaw.SwanBaseBreakpointSmEnd})`}
            />
            <source
              srcSet='https://cms.cloudinary.vpsvc.com/image/upload/c_scale,dpr_auto,f_auto,w_auto/vista-logos-pages/en-US/business-name-generator/BusinessNameGenerator_3840x1000_xl_lg_md_rev_b.webp'
              media={`(min-width: ${tokensRaw.SwanBaseBreakpointMdStart})`}
            />
            <HeroImage
              srcSet='https://cms.cloudinary.vpsvc.com/image/upload/c_scale,dpr_auto,f_auto,w_auto/vista-logos-pages/en-US/business-name-generator/BusinessNameGenerator_1840x1000_sm_xs_rev_b.webp'
              alt='A barber shop scene featuring the barber standing in front of a mirror holding a hairdryer close to the head of a client with a short haircut.'
            />
            <FeaturedNames
              names={['Fresh Fade Barbers', 'Clip \'N Snip', 'Trims & Trends']}
            />
          </FluidImage>
        </BannerImage>
      </BannerImageContainer>
    </Banner>
  )
}

export default Hero
